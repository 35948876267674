import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { ApiSettings } from '@hultafors/shared/types';

import { GlobalFields } from '@hultafors/hultaforsgroup/types';

import { GlobalProvider } from '../global-context/global-context';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);
interface AppProviderProps {
  children?: React.ReactNode;
  value: GlobalFields & {
    settings: ApiSettings;
  };
}

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
  value: { settings, ...value },
}) => {
  const { locale } = useRouter();
  const swrConfig = {
    revalidateIfStale: false,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  function getActiveLocale(locale: string | undefined) {
    switch (locale) {
      case 'en':
        return 'www.hultaforsgroup.com';
      case 'en-gb':
        return 'www.hultaforsgroup.co.uk';
      case 'en-ie':
        return 'www.hultaforsgroup.ie';
      case 'da-dk':
        return 'www.hultaforsgroup.dk';
      case 'de-at':
        return 'www.hultaforsgroup.at';
      case 'de-ch':
        return 'www.hultaforsgroup.ch';
      case 'de-de':
        return 'www.hultaforsgroup.de';
      case 'fi-fi':
        return 'www.hultaforsgroup.fi';
      case 'fr-fr':
        return 'www.hultaforsgroup.fr';
      case 'it-it':
        return 'www.hultaforsgroup.it';
      case 'nb-no':
        return 'www.hultaforsgroup.no';
      case 'nl-nl':
        return 'www.hultaforsgroup.nl';
      case 'nl-be':
        return 'www.hultaforsgroup.be';
      case 'pl-pl':
        return 'www.hultaforsgroup.pl';
      case 'sv-se':
        return 'www.hultaforsgroup.se';
      default:
        return 'www.hultaforsgroup.com'; // Fallback case
    }
  }

  const active = getActiveLocale(locale).replace('https://', '');

  return (
    <SWRProvider config={swrConfig}>
      <LazyMotion features={lazyMotionFeatures}>
        <GlobalProvider value={value} settings={settings}>
          <MarketPickerProvider
            label={value?.global?.changeLanguage ?? ''}
            markets={value?.global?.languages || []}
            active={active}
          >
            {children}
          </MarketPickerProvider>
        </GlobalProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
